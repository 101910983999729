import Link from "next/link";
import { Center, Group, Paper, Stack, Text, Title } from "@mantine/core";
import Image from "next/image";

export interface IUserFormCard {
	id: number;
	title: string;
	companyName: string;
	status: string;
	progress: string;
	link: string;
	brand: string;
}

export default function UserFormCard(props: IUserFormCard) {
	function getImage() {
		switch (props.brand) {
			case "Chicken Treat":
				return "/cc-logo.png";
			case "Oporto":
				return "/oporto-logo.svg";
			case "Red Rooster":
				return "/rr-logo.svg";
			default:
				return "/craveablelogo_rev.png";
		}
	}

	function getBackgroundColor() {
		switch (props.brand) {
			case "Chicken Treat":
				return "#f1d535";
			case "Oporto":
				return "#231F20";
			case "Red Rooster":
				return "#b0151e";
			default:
				return "/craveablelogo_rev.png";
		}
	}

	function getTextColor() {
		switch (props.brand) {
			case "Chicken Treat":
				return "dark";
			case "Oporto":
				return "gray.0";
			case "Red Rooster":
				return "gray.0";
			default:
				return "gray.0";
		}
	}

	return (
		<Link
			href={props.link}
			passHref
		>
			<Paper
				component={"div"}
				radius={"lg"}
				p={"md"}
				sx={() => ({
					backgroundColor: getBackgroundColor(),
					transition: "box-shadow 0.2s ease",
					"&:hover": {
						boxShadow: "0px 0px 10px 2px #0004",
					}
				})}
			>
				<Stack w={350} spacing={"xl"}>
					<Center style={{height: "100%"}}>
						<Image
							src={getImage()}
							alt={props.brand}
							height={80}
							width={200}
							style={{width: "auto"}}
						/>
					</Center>
					<Stack>
						<Title size={"h2"} color={`${getTextColor()}`} align={"center"}>{props.title}</Title>
						<Group position={"apart"}>
							<Text size={"sm"} color={`${getTextColor()}`} weight={500}>{props.status}</Text>
							<Text
								color={props.status === "Complete" ? "green" : getTextColor()} weight={500}
							>{props.progress}</Text>
						</Group>
					</Stack>
				</Stack>

			</Paper>
		</Link>
	);
}