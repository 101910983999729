import UserFormCard from "../userFormCard/userFormCard";
import { Loader, Stack, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { SurveyAPIModel } from "../../models/generalResults.model";
import { useContext } from "react";
import { ApiContext, IApiError, onError } from "../../context/api.context";
import { ValidateGeneralResultsAll } from "../../helpers/surveyValidation";
import { FormStatus } from "../../models/form.model";
//import { ValidateGeneralResultsAll } from "../../helpers/generalValidation";

export default function GeneralResultsList() {
	const {GetSurveyResults: GetGeneralFormResults} = useContext(ApiContext);
	const {
		isLoading,
		error,
		data
	} = useQuery<SurveyAPIModel[], IApiError>(
		['generalResults'],
		GetGeneralFormResults,
		{onError, retry: false}
	);

	return (
		<Stack align={"center"}>
			<Title>2023 Compliance Surveys</Title>
			{error && <Text>{`Error: ${error.title}`}</Text>}
			{isLoading && <Loader/>}
			{
				data && data.map((item) => {
					const results = ValidateGeneralResultsAll(item);
					return (
						<UserFormCard
							key={item.id}
							id={item.id}
							//title={item.form.title}
							title={`${item.storeName} ${item.storeBrand}`}
							companyName={item.franchiseeCompany}
							status={FormStatus[item.statusId]}
							progress={`${results.completeCount}/${results.totalCount}`}
							link={`/surveys/${item.id}`}
							brand={item.storeBrand}
						/>
					);
				})
			}
			{
				!isLoading && !error && !data &&
				<Text>No results found for this user</Text>
			}
		</Stack>
	);
}