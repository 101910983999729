import {
	SurveyResultData,
	SurveyTab1,
	SurveyTab2,
	SurveyTab3,
	SurveyTab4,
	SurveyTab5,
	SurveyTab6,
	ISurveyTab1Static,
	ISurveyTab2Static,
	ISurveyTab3Static,
	ISurveyTab4Static,
	ISurveyTab5Static,
	ISurveyTab6Static, SurveyAPIModel,
} from "../models/generalResults.model";
import { IResultProgressErrors } from "../models/form.model";
import { GetValidationTotals, ValidationText } from "./helpers";
import { ITabProgress } from "../components/tabs/survey/surveyTabList";
import { UseFormReturnType } from "@mantine/form";

export function ValidateGeneralResultsAll(values: SurveyResultData): IResultProgressErrors {
	const tab1 = ValidateGeneralResultsTab1(values);
	const tab2 = ValidateGeneralResultsTab2(values);
	const tab3 = ValidateGeneralResultsTab3(values);
	const tab4 = ValidateGeneralResultsTab4(values);
	const tab5 = ValidateGeneralResultsTab5(values);
	const tab6 = ValidateSurveyResultsTab6(values);

	const totalCount = tab1.totalCount
		+ tab2.totalCount
		+ tab3.totalCount
		+ tab4.totalCount
		+ tab5.totalCount
		+ tab6.totalCount;

	const completeCount = tab1.completeCount
		+ tab2.completeCount
		+ tab3.completeCount
		+ tab4.completeCount
		+ tab5.completeCount
		+ tab6.completeCount;

	const errors = {
		...tab1.errors,
		...tab2.errors,
		...tab3.errors,
		...tab4.errors,
		...tab5.errors,
		...tab6.errors
	};

	return ({
		totalCount,
		completeCount,
		errors: (errors as { [K in keyof SurveyResultData]: string | null })
	});
}

//Primary Compliance
export function ValidateGeneralResultsTab1(values: SurveyTab1): IResultProgressErrors {
	const errors: { [K in keyof ISurveyTab1Static]: string | null } = {
		franchiseeName: values.franchiseeName === null ? ValidationText.SelectValue : null,
		franchiseeNameUpdated: values.franchiseeNameUpdated === null ? ValidationText.SelectValue : null,
		franchiseeEmail: values.franchiseeEmail === null ? ValidationText.SelectValue : null,
		franchiseeEmailUpdated: values.franchiseeEmailUpdated === null ? ValidationText.SelectValue : null,
		franchiseeMobile: values.franchiseeMobile === null ? ValidationText.SelectValue : null,
		franchiseeMobileUpdated: values.franchiseeMobileUpdated === null ? ValidationText.SelectValue : null,
		franchiseeCompany: values.franchiseeCompany === null ? ValidationText.SelectValue : null,
		franchiseeCompanyUpdated: values.franchiseeCompanyUpdated === null ? ValidationText.SelectValue : null,
		franchiseeAbn: values.franchiseeAbn === null ? ValidationText.SelectValue : null,
		franchiseeAbnupdated: values.franchiseeAbnupdated === null ? ValidationText.SelectValue : null,
	};
	const allErrors = errors as { [K in keyof SurveyTab1]: string | null };
	//No conditional errors

	return GetValidationTotals(errors);
}

//General Compliance
export function ValidateGeneralResultsTab2(values: SurveyTab2): IResultProgressErrors {
	const errors: { [K in keyof ISurveyTab2Static]: string | null } = {
		workersCompensation: values.workersCompensation === null ? ValidationText.SelectValue : null,
		publicLiability: values.publicLiability === null ? ValidationText.SelectValue : null
	};
	const allErrors = errors as { [K in keyof SurveyTab2]: string | null };
	if (!values.workersCompensation) {
		allErrors.workersCompensationReason = !values.workersCompensationReason ? ValidationText.EnterValue : null;
	}
	if (values.workersCompensation) {
		allErrors.workersCompensationFileName = !values.workersCompensationFileName ? ValidationText.SelectFile : null;
	}
	if (!values.publicLiability) {
		allErrors.publicLiabilityReason = !values.publicLiabilityReason ? ValidationText.EnterValue : null;
	}
	if (values.publicLiability) {
		allErrors.publicLiabilityFileName = !values.publicLiabilityFileName ? ValidationText.SelectFile : null;
	}

	return GetValidationTotals(allErrors);
}

//Employee Details
export function ValidateGeneralResultsTab3(values: SurveyTab3): IResultProgressErrors {
	const errors: { [K in keyof ISurveyTab3Static]: string | null } = {
		adiEmployeeListing: values.adiEmployeeListing === null ? ValidationText.SelectValue : null
	};
	const allErrors = errors as { [K in keyof SurveyTab3]: string | null };
	//conditional validation
	if (!values.adiEmployeeListing) {
		allErrors.adiEmployeeListingReason = !values.adiEmployeeListingReason ? ValidationText.EnterValue : null;
	}
	if (values.adiEmployeeListing) {
		allErrors.adiEmployeeListingFileName = !values.adiEmployeeListingFileName ? ValidationText.SelectFile : null;
	}
	return GetValidationTotals(allErrors);
}

//Payroll Process
export function ValidateGeneralResultsTab4(values: SurveyTab4): IResultProgressErrors {
	const errors: { [K in keyof ISurveyTab4Static]: string | null } = {
		industrialInstrumentFastFood: values.industrialInstrumentFastFood === null ? ValidationText.SelectValue : null,
		payrollAnnualSalaryEmployees: values.payrollAnnualSalaryEmployees === null ? ValidationText.SelectValue : null,
		payrollPartTimeEmployees: values.payrollPartTimeEmployees === null ? ValidationText.SelectValue : null,
		hrpayrollServiceProvider: values.hrpayrollServiceProvider === null ? ValidationText.SelectValue : null,
		payslipSample: values.payslipSample === null ? ValidationText.SelectValue : null,
		payrollFlatRateEmployees: values.payrollFlatRateEmployees === null ? ValidationText.SelectValue : null,
		payrollSummaryFileName: values.payrollSummaryFileName === null ? ValidationText.SelectFile : null,
		superPaymentEvidence: values.superPaymentEvidence === null ? ValidationText.SelectValue : null,
		atoreportingSchedule: values.atoreportingSchedule === null ? ValidationText.SelectValue : null,
		employeesUnder16: values.employeesUnder16 === null ? ValidationText.SelectValue : null,
		integratedClientAccount: values.integratedClientAccount === null ? ValidationText.SelectValue : null,
	};
	const allErrors = errors as { [K in keyof SurveyTab4]: string | null };
	//conditional validation
	if (!values.industrialInstrumentFastFood) {
		allErrors.industrialInstrumentOther = !values.industrialInstrumentOther ? ValidationText.EnterValue : null;
	}
	if (values.payrollAnnualSalaryEmployees) {
		allErrors.payrollAnnualSalaryEmployeesFileName = !values.payrollAnnualSalaryEmployeesFileName ? ValidationText.SelectFile : null;
	}
	if (values.payrollFlatRateEmployees) {
		allErrors.payrollFlatRateEmployeesFileName = !values.payrollFlatRateEmployeesFileName ? ValidationText.SelectFile : null;
	}
	if (values.payrollPartTimeEmployees) {
		allErrors.payrollPartTimeEmployeesFileName = !values.payrollPartTimeEmployeesFileName ? ValidationText.SelectFile : null;
	}
	//NO SERVICE PROVIDER BRANCH
	//If they do not have a service provider - indicate what payroll system
	if (!values.hrpayrollServiceProvider) {
		allErrors.payrollSystem = !values.payrollSystem ? ValidationText.EnterValue : null;
	}
	//If they do not have a service provider AND have selected Other payroll system - indicate Payroll Other
	if (values.hrpayrollServiceProvider === false && values.payrollSystem === "Other") {
		allErrors.payrollSystemOther = !values.payrollSystemOther ? ValidationText.EnterValue : null;
	}
	//SERVICE PROVIDER BRANCH
	//If they have a service provider - need to indicate if it is IWS
	if (values.hrpayrollServiceProvider) {
		allErrors.hrpayrollServiceProviderIws = values.hrpayrollServiceProviderIws === null ? ValidationText.SelectValue : null;
	}
	//If they have a service provider and it is NOT IWS - indicate other
	if (values.hrpayrollServiceProvider && !values.hrpayrollServiceProviderIws) {
		allErrors.hrpayrollServiceProviderOther = !values.hrpayrollServiceProviderOther ? ValidationText.SelectValue : null;
	}
	//Payslip sample
	if (values.payslipSample) {
		allErrors.payslipSampleFileName = !values.payslipSampleFileName ? ValidationText.SelectFile : null;
	}
	if (!values.payslipSample) {
		allErrors.payslipSampleOther = !values.payslipSampleOther ? ValidationText.EnterValue : null;
	}
	//Super evidence
	if (values.superPaymentEvidence) {
		allErrors.superPaymentEvidenceFilename = !values.superPaymentEvidenceFilename ? ValidationText.SelectFile : null;
	}
	if (!values.superPaymentEvidence) {
		allErrors.superPaymentEvidenceOther = !values.superPaymentEvidenceOther ? ValidationText.EnterValue : null;
	}
	//ATO Reporting
	if (values.atoreportingSchedule === "Quarterly") {
		allErrors.atoreportingScheduleQuarterlyFileName = !values.atoreportingScheduleQuarterlyFileName ? ValidationText.SelectFile : null;
	}
	if (values.atoreportingSchedule === "Monthly") {
		allErrors.atoreportingScheduleMonthlyJanFileName = !values.atoreportingScheduleMonthlyJanFileName ? ValidationText.SelectFile : null;
		allErrors.atoreportingScheduleMonthlyFebFileName = !values.atoreportingScheduleMonthlyFebFileName ? ValidationText.SelectFile : null;
		allErrors.atoreportingScheduleMonthlyMarchFileName = !values.atoreportingScheduleMonthlyMarchFileName ? ValidationText.SelectFile : null;
	}
	if (values.employeesUnder16 && !values.employeesUnder16Reason) {
		allErrors.employeesUnder16FileName = !values.employeesUnder16FileName ? ValidationText.SelectFile : null;
	}
	if (values.employeesUnder16 && !values.employeesUnder16FileName) {
		allErrors.employeesUnder16Reason = !values.employeesUnder16Reason ? ValidationText.EnterValue : null;
	}
	//Integrated Client Account
	if (values.integratedClientAccount) {
		allErrors.integratedClientAccountFileName = !values.integratedClientAccountFileName ? ValidationText.SelectFile : null;
	}
	if (!values.integratedClientAccount) {
		allErrors.integratedClientAccountOther = !values.integratedClientAccountOther ? ValidationText.EnterValue : null;
	}
	return GetValidationTotals(allErrors);
}

//Franchisee Statements
export function ValidateGeneralResultsTab5(values: SurveyTab5): IResultProgressErrors {
	const errors: { [K in keyof ISurveyTab5Static]: string | null } = {
		statementPaymentObligations: values.statementPaymentObligations === null ? ValidationText.SelectValue : null,
		statementRecordKeeping: values.statementRecordKeeping === null ? ValidationText.SelectValue : null,
		statementVisaDocumentation: values.statementVisaDocumentation === null ? ValidationText.SelectValue : null,
		statementCeisprovision: values.statementCeisprovision === null ? ValidationText.SelectValue : null,
		statementLeaveEntitlements: values.statementLeaveEntitlements === null ? ValidationText.SelectValue : null,
		statementAdiUsage: values.statementAdiUsage === null ? ValidationText.SelectValue : null,
		statementMinimumAge: values.statementMinimumAge === null ? ValidationText.SelectValue : null,
		statementJuniorRosteringRules: values.statementJuniorRosteringRules === null ? ValidationText.SelectValue : null

	};
	const allErrors = errors as { [K in keyof SurveyTab5]: string | null };
	if (!values.statementPaymentObligations) {
		allErrors.statementPaymentObligationsQuery = !values.statementPaymentObligationsQuery ? ValidationText.EnterValue : null;
	}
	if (!values.statementRecordKeeping) {
		allErrors.statementRecordKeepingQuery = !values.statementRecordKeepingQuery ? ValidationText.EnterValue : null;
	}
	if (!values.statementVisaDocumentation) {
		allErrors.statementVisaDocumentationQuery = !values.statementVisaDocumentationQuery ? ValidationText.EnterValue : null;
	}
	if (!values.statementCeisprovision) {
		allErrors.statementCeisprovisionQuery = !values.statementCeisprovisionQuery ? ValidationText.EnterValue : null;
	}
	if (!values.statementLeaveEntitlements) {
		allErrors.statementLeaveEntitlementsQuery = !values.statementLeaveEntitlementsQuery ? ValidationText.EnterValue : null;
	}
	if (!values.statementAdiUsage) {
		allErrors.statementAdiUsageQuery = !values.statementAdiUsageQuery ? ValidationText.EnterValue : null;
	}
	if (!values.statementMinimumAge) {
		allErrors.statementMinimumAgeQuery = !values.statementMinimumAgeQuery ? ValidationText.EnterValue : null;
	}
	if (!values.statementJuniorRosteringRules) {
		allErrors.statementJuniorRosteringRulesQuery = !values.statementJuniorRosteringRulesQuery ? ValidationText.EnterValue : null;
	}
	return GetValidationTotals(allErrors);
}

//Survey
export function ValidateSurveyResultsTab6(values: SurveyTab6): IResultProgressErrors {
	const errors: { [K in keyof ISurveyTab6Static]: string | null } = {
		craveableBrandsSupport: values.craveableBrandsSupport === null ? ValidationText.SelectValue : null
	};
	const allErrors = errors as { [K in keyof SurveyTab6]: string | null };
	if (values.craveableBrandsSupport! <= 7) {
		allErrors.craveableBrandsSupportQuery = !values.craveableBrandsSupportQuery ? ValidationText.EnterValue : null;
	}

	return GetValidationTotals(allErrors);
}


export function GetGeneralTabValidationProgress(progress: ITabProgress, activeTab: number, values: SurveyResultData) {
	switch (activeTab) {
		case 1:
			progress[1] = ValidateGeneralResultsTab1(values);
			break;
		case 2:
			progress[2] = ValidateGeneralResultsTab2(values);
			break;
		case 3:
			progress[3] = ValidateGeneralResultsTab3(values);
			break;
		case 4:
			progress[4] = ValidateGeneralResultsTab4(values);
			break;
		case 5:
			progress[5] = ValidateGeneralResultsTab5(values);
			break;
		case 6:
			progress[6] = ValidateSurveyResultsTab6(values);
			break;
	}
	return progress;
}

export function GetGeneralInitialValidationProgress(values: SurveyResultData) {
	const progressErrors: ITabProgress = {
		1: ValidateGeneralResultsTab1(values),
		2: ValidateGeneralResultsTab2(values),
		3: ValidateGeneralResultsTab3(values),
		4: ValidateGeneralResultsTab4(values),
		5: ValidateGeneralResultsTab5(values),
		6: ValidateSurveyResultsTab6(values)
	};
	return progressErrors;
}

export function SetInitialFranchiseeDetails(form: UseFormReturnType<SurveyAPIModel>) {
	if (!form.values.franchiseeName) form.setFieldValue("franchiseeNameUpdated", false);
	if (!form.values.franchiseeEmail) form.setFieldValue("franchiseeEmailUpdated", false);
	if (!form.values.franchiseeMobile) form.setFieldValue("franchiseeMobileUpdated", false);
	if (!form.values.franchiseeCompany) form.setFieldValue("franchiseeCompanyUpdated", false);
	if (!form.values.franchiseeAbn) form.setFieldValue("franchiseeAbnupdated", false);
}