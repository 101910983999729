import Head from 'next/head';
import styles from '../styles/Home.module.css';
import { Config } from "../helpers/config";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Stack } from "@mantine/core";
import Link from "next/link";
import AppWrapper from '../components/appWrapper/appWrapper';
import GeneralResultsList from '../components/generalResultsList/generalResultsList';

export default function Home() {
	const [token, setToken] = useState<string>();
	const [loading, setLoading] = useState(true);

	const {
		isAuthenticated,
		isLoading,
		getAccessTokenSilently,
		logout,
		loginWithRedirect,
		user,
		error
	} = useAuth0();

	useEffect(() => {
		async function getToken() {
			const accessToken = await getAccessTokenSilently({
				authorizationParams: {
					audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
					scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
				}
			});
			setToken(accessToken);
			setLoading(false);
		}

		if (!isLoading && isAuthenticated) {
			getToken();
		}

		if (!isLoading && !isAuthenticated) {
			loginWithRedirect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isAuthenticated, user, error]);

	return (
		<div className={styles.container}>
			<AppWrapper>
				<Head>
					<title>{`Home | ${Config.title}`}</title>
				</Head>
				<Stack spacing={48}>
					<GeneralResultsList/>
					<div>
						{
							!isAuthenticated && !isLoading &&
							<Link href={"login"}>
								Go to Login
							</Link>
						}
					</div>
				</Stack>
			</AppWrapper>
		</div>
	)
}
