import { FormErrors } from "@mantine/form/lib/types";

export interface IForm {
	id: number;
	title: string;
}

export interface IResultProgressErrors {
	totalCount: number;
	completeCount: number;
	errors: FormErrors;
}

export enum FormStatus {
	Incomplete,
	Complete
}